import type {
  IProject,
  ProjectAddress
} from '../models';

export const getTitle = (routerName: string): string => {
  let title = '';
  let convertedTitle = '';
  if (routerName.startsWith('/access_token')) {
    convertedTitle = '';
  } else {
    if (routerName.includes('/company/edit')) {
      convertedTitle = 'Company Info';
    } else if (routerName.includes('/payment/edit')) {
      convertedTitle = 'Payment Info';
    } else if (routerName.includes('/profile')) {
      convertedTitle = 'Profile';
    } else {
      convertedTitle = routerName.replace(/\//g, '');
    }
  }

  title = convertedTitle.charAt(0).toUpperCase() + convertedTitle.slice(1);
  return title;
};

export const getProjectName = (project: IProject): string => {
  const {
    address: {
      addressOne, city, province, postalCode
    },
    internalReferenceId,
    customer
  } = project;

  let projectName = `${addressOne} ${city} ${province} ${postalCode}`;

  if (internalReferenceId) {
    projectName += `ID #${internalReferenceId}`;
  }
  if (customer) {
    const {
      firstName, lastName
    } = customer;
    projectName += `- ${firstName ? `${firstName} ` : ''}${lastName}`;
  }
  return projectName;
};

export const getRefText = (project: IProject): string => {
  const { internalReferenceId } = project;

  return `ID # ${internalReferenceId}`;
};

export const getCustomerNameText = (project: IProject): string => {
  const { customer } = project;
  return customer ? `${customer.firstName ? `${customer.firstName} ` : ''}${customer.lastName}` : '';
};

export const getAddressText = (project: IProject): string => {
  const {
    address: {
      city, addressOne, province, postalCode
    },
  } = project;

  return `${addressOne} ${city} ${province} ${postalCode}`;
};

export const getAddressObject = (placeObject: google.maps.places.PlaceResult): ProjectAddress => {
  let numb = '';
  let street = '';
  let city = '';
  let province = '';
  let postalCode = '';
  let subLocality = '';
  let neighborhood = '';
  let country = '';

  const latitude = placeObject.geometry?.location?.lat() ?? 0;
  const longitude = placeObject.geometry?.location?.lng() ?? 0;

  placeObject.address_components?.forEach(
    (component: google.maps.GeocoderAddressComponent) => {
      if (component.types.includes('street_number')) {
        numb = component.short_name;
      }
      if (component.types.includes('route')) {
        street = component.short_name;
      }
      if (component.types.includes('locality')) {
        city = component.long_name;
      }
      if (!city && component.types.includes('administrative_area_level_3')) {
        city = component.long_name;
      }
      if (component.types.includes('administrative_area_level_1')) {
        province = component.short_name;
      }
      if (component.types.includes('postal_code')) {
        postalCode = component.short_name;
      }
      if (component.types.includes('sublocality') || component.types.includes('sublocality_level_1')) {
        subLocality = component.short_name;
      }
      if (component.types.includes('neighborhood')) {
        neighborhood = component.short_name;
      }
      if (component.types.includes('country')) {
        country = component.short_name;
      }
    }
  );

  // Special cases if no values for city found.
  if (!city && subLocality) {
    city = subLocality;
  }
  if (!city && neighborhood) {
    city = neighborhood;
  }

  return {
    city,
    province: country === 'PR' ? 'PR' : province, // Special case when GoogleMaps has PuertoRico as different country.
    postalCode,
    coordinates: {
      latitude,
      longitude,
      zoom: 20 // FIXME: update default zoom level
    },
    addressOne: `${numb} ${street}`,
  };
};

export const getStatusText = (statusIndex: number): string => {
  if (statusIndex === 0) {
    return 'active';
  } else if (statusIndex === 1) {
    return 'inactive';
  } else {
    return 'pending';
  }
};

type ClassnameToFlag = {
  [key: string]: boolean;
};

export const classnames = (classnameToFlagMap: ClassnameToFlag): string =>
  Object.keys(classnameToFlagMap).reduce((result, key) => {
    return `${result} ${classnameToFlagMap[key] ? key : ''}`;
  }, '');

export const formatNumberWithCommas = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const canUseDOM = (): boolean => (!!window.document && !!window.document.createElement);

export const isLyraUserId = (s: string): boolean => {
  const auth0 = s.includes('auth0|');
  const uuid = s.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  ); // uuid v1-5 regex
  return auth0 || !!uuid?.length;
};
