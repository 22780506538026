import { DropDownProxy as LyraDropDownProxy } from '@aurorasolar/lyra-ui-kit/lib/components/DropDownProxy/DropDownProxy';
import type {
  FC, ReactElement
} from 'react';
import React, {
  useCallback, useState
} from 'react';
import {
  ACCOUNT_TYPES, DROPDOWN_PORTAL_ID, PRIMARY_USE_OPTIONS
} from '../../../../constants';
import type { IInitAccountParams } from '../../../../services/AuthService';
import { useStore } from '../../../../stores';
import { classnames } from '../../../../utils/helper';
import { AlertMessage } from '../../../AlertMessage/AlertMessage';
import {
  LyraButton, LyraInput
} from '../../../Common/LyraComponents';
import {
  HeaderTitle, PaddingView
} from '../../../Common/styles';
import { ModalWrapper } from '../../index';
import {
  ButtonContainer, CompanyFormWrapper, Footer, Header
} from './AccountSetupModal.styles';

type AccountSetupModalProps = {
  modalShow: boolean;
  showEmailConfirmed?: boolean;
  emailConfirmedMessage?: string;
  setModalShow: (arg0: boolean) => void;
};

const AccountSetupModal: FC<AccountSetupModalProps> = ({
  modalShow,
  setModalShow,
  showEmailConfirmed,
  emailConfirmedMessage,
}: AccountSetupModalProps): ReactElement => {
  const { auth: authStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const [accountTypeValue, setAccountTypeValue] = useState('INSTALLATION_COMPANY');
  const [companyName, setCompanyName] = useState('');
  const [primaryUseValue, setPrimaryUseValue] = useState('PERMIT_PACKAGES');

  const isInstaller = accountTypeValue === 'INSTALLATION_COMPANY';

  const completeSetupButtonHandler = useCallback(async () => {
    try {
      setLoading(true);
      const params: IInitAccountParams = {
        accountType: accountTypeValue
      };
      if (isInstaller) {
        params.companyName = companyName;
        params.primaryUse = primaryUseValue;
      }

      await authStore.completeAccountSetup(params);

      setLoading(false);
      setError(false);
      setSuccess(true);
      setModalShow(false);
    } catch (err) {
      setLoading(false);
      setError(true);
    }
  }, [authStore, accountTypeValue, isInstaller, companyName, primaryUseValue, setModalShow]);

  const onAccountTypeSelect = useCallback((value: string): void => {
    if (value !== accountTypeValue) {
      setCompanyName('');
      setAccountTypeValue(value);
    }
  }, [accountTypeValue]);

  const onPrimaryUseSelect = useCallback((value: string): void => {
    setPrimaryUseValue(value);
  }, []);

  const modalStyles: React.CSSProperties = {
    top: '20%',
    left: '30%',
    right: '30%',
    bottom: '20%',
    display: 'flex',
    flexDirection: 'column'
  };
  const isAccountTypeEmpty = !accountTypeValue;
  const isCompanyEmpty = accountTypeValue === 'INSTALLATION_COMPANY' && (!companyName || !primaryUseValue);

  const buttonClassNames = classnames({
    disabled: isAccountTypeEmpty || isCompanyEmpty,
  });

  return (
    <ModalWrapper
      isOpen={modalShow}
      contentStyle={modalStyles}
    >
      <Header>
        <HeaderTitle>Set up your Lyra account</HeaderTitle>
      </Header>
      <AlertMessage
        type="success"
        message={emailConfirmedMessage!}
        isMessageVisible={showEmailConfirmed!}
      />

      <PaddingView>
        <LyraDropDownProxy
          placeholder="Select Account Type"
          items={ACCOUNT_TYPES}
          name="Account Type *"
          value={accountTypeValue}
          onSelect={onAccountTypeSelect}
          menuPortalTarget={document.getElementById(DROPDOWN_PORTAL_ID)}
        />
      </PaddingView>
      <CompanyFormWrapper>
        {isInstaller && (
          <div>
            <PaddingView>
              <LyraInput.HAInput
                name="companyName"
                label="Company Name *"
                placeholder="Enter Company Name"
                onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                  setCompanyName(event.target.value)
                }
              />
            </PaddingView>
            <PaddingView>
              <LyraDropDownProxy
                placeholder="Select Primary Use"
                items={PRIMARY_USE_OPTIONS}
                name="Primary Use *"
                value={primaryUseValue}
                onSelect={onPrimaryUseSelect}
                menuPortalTarget={document.getElementById(DROPDOWN_PORTAL_ID)}
              />
            </PaddingView>
          </div>
        )}
      </CompanyFormWrapper>
      <Footer>
        <ButtonContainer>
          <LyraButton styleType="primary" className={buttonClassNames} onClick={completeSetupButtonHandler}>
            GET STARTED!
          </LyraButton>
        </ButtonContainer>
      </Footer>
    </ModalWrapper>
  );
};

AccountSetupModal.defaultProps = {
  showEmailConfirmed: false,
  emailConfirmedMessage: ''
};

export { AccountSetupModal };
