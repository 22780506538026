import { lyraDesignToolVersion } from '@aurorasolar/lyra-design-tool';

const appEnv = process.env.REACT_APP_ENV;

const environment = {
  design: {
    environment: appEnv,
    name: process.env.LYRA_ENV_DESIGN_TOOL_NAME,
    version: lyraDesignToolVersion
  },
  host: {
    environment: appEnv,
    name: process.env.REACT_APP_NAME, // webpack/utils/plugins
    version: process.env.REACT_APP_VERSION, // webpack/utils/plugins
    updatePaymentUrl: process.env.LYRA_ENV_FEATURE_UPDATE_PAYMENT_LINK
  },
  node: process.env.NODE_ENV
};

// Lyra API
const apiHostUrl = process.env.LYRA_ENV_API_HOST_URL!;
const apiDocumentsUrl = process.env.LYRA_ENV_API_DOCUMENTS!;
const apiDesignUrl = process.env.LYRA_ENV_API_DESIGN!;

const api = {
  host: apiHostUrl,
  documents: apiDocumentsUrl,
  design: apiDesignUrl
};

// Site info
const site = {
  // Canonical root url is needed in social media sharing and SEO optimization purposes.
  canonicalRootUrl: process.env.LYRA_ENV_CANONICAL_ROOT_URL ?? ''
};

// Google Maps
// Google Maps API key is needed for static map images.
const maps = {
  // Google Maps API
  google: {
    apiKey: `${process.env.LYRA_ENV_GOOGLE_MAPS_API_KEY}`,
    client: `${process.env.LYRA_ENV_GOOGLE_MAPS_CLIENT}`,
    privateKey: `${process.env.LYRA_ENV_GOOGLE_MAPS_PRIVATE_KEY}`,
    staticUrl: `${process.env.LYRA_ENV_GOOGLE_MAPS_STATIC_URL}`,
    scale: `${process.env.LYRA_ENV_GOOGLE_MAPS_SCALE}`,
    size: `${process.env.LYRA_ENV_MAPS_RESOLUTION_X}x${process.env.LYRA_ENV_MAPS_RESOLUTION_Y}`,
    disableDefaultUI: `${process.env.LYRA_ENV_GOOGLE_MAPS_DISABLE_DEFAULT_UI}`,
    tilt: `${process.env.LYRA_ENV_GOOGLE_MAPS_TILT}`,
    factorHeading: `${process.env.LYRA_ENV_GOOGLE_MAPS_FACTOR_HEADING}`,
    pitch: `${process.env.LYRA_ENV_GOOGLE_MAPS_PITCH}`,
    zoom: Number(process.env.LYRA_ENV_GOOGLE_MAPS_ZOOM)
  }
};

// Sentry DSN (Data Source Name), a client key for authenticating calls to Sentry
const sentryMode = process.env.LYRA_ENV_HOST_APP_SENTRY_ENABLED === 'true';
const sentryEnvironment = environment.host.environment;
const sentryDsn = process.env.LYRA_ENV_HOST_APP_SENTRY_DSN;
const sentryMaxBreadcrumbs = Number(process.env.LYRA_ENV_SENTRY_BREADCRUMBS) || 100;
const sentryDebug = process.env.LYRA_ENV_SENTRY_DEBUG === 'true';
const sentryLogLevel = process.env.LYRA_ENV_SENTRY_LOG_LEVEL;
const sentryAttachStacktrace = process.env.LYRA_ENV_SENTRY_STACKTRACE === 'true';
const sentryAutoSessionTracking = process.env.LYRA_ENV_SENTRY_SESSION_TRACKING === 'true';
const sentryMaxStoredEvents = Number(process.env.LYRA_ENV_SENTRY_MAX_STORED_EVENTS);
// Set tracesSampleRate to 1.0 to capture 100%
// of transactions for performance monitoring.
// It is recommended to adjust this value in production
const sentryErrorDataIntegrationDepth = Number(process.env.LYRA_ENV_SENTRY_DATA_INTEGRATION_DEPTH) || 3;
const sentryTraceSampleRate = Number(process.env.LYRA_ENV_SENTRY_TRACE_SAMPLE_RATE) || 1.0;
const sentryCaptureConsole = ['error', 'debug']; // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
const showSentryDialog = process.env.LYRA_ENV_SHOW_ERROR_DIALOG === 'true';
const ignoredSentryCodes = [
  'csp-violation',
  'server-side-data-load-failed',
  'card_declined',
  'currency-input-invalid-currency',
  'auth-info-failed',
  'signup-failed',
  'fetch-current-user-failed'
  // 'browser-side-render-failed' // catched intl invariant errors,
];
const sentry = {
  enabled: sentryMode,
  dsn: sentryDsn,
  debug: sentryDebug,
  showDialog: showSentryDialog,
  ignoredCodes: ignoredSentryCodes,
  tracesSampleRate: sentryTraceSampleRate,
  dataIntegrationDepth: sentryErrorDataIntegrationDepth,
  environment: sentryEnvironment,
  maxBreadcrumbs: sentryMaxBreadcrumbs,
  logLevel: sentryLogLevel,
  attachStacktrace: sentryAttachStacktrace,
  autoSessionTracking: sentryAutoSessionTracking,
  maxStoredEvents: sentryMaxStoredEvents,
  captureConsole: sentryCaptureConsole
};

// HubSpot integration
// See: https://developers.hubspot.com/docs/api/events/tracking-code
// See: https://developers.hubspot.com/docs/api/conversation/chat-widget-sdk
//
const hubSpotHubId = process.env.LYRA_ENV_HUBSPOT_HUB_ID;
const hubSpotEnabled = process.env.LYRA_ENV_HUBSPOT_ENABLED === 'true';
const hubSpot = {
  hubId: hubSpotHubId,
  enabled: hubSpotEnabled
};

const analytics = {
  enabled: process.env.LYRA_ENV_ANALYTICS_TRACKING === 'true',
  provider: process.env.LYRA_ENV_ANALYTICS_PROVIDER,
  id:
    process.env.LYRA_ENV_ANALYTICS_PROVIDER === 'segment'
      ? process.env.LYRA_ENV_SEGMENT_ID
      : process.env.LYRA_ENV_GOOGLE_ANALYTICS_ID
};

export const validRoutes: RegExp[] = [
  /^\/$/,
  /^\/index.html\/?$/,
  /^\/register\/?$/,
  /^\/forgot-password\/?$/,
  /^\/projects\/\d+\/?$/,
  /^\/dashboard\/?$/,
  /^\/users\/?$/,
  /^\/profile\/?$/,
  /^\/account\/?$/,
  /^\/account\/company\/edit\/?$/,
  /^\/account\/payment\/edit\/?$/,
  /^\/preferences\/?$/
];

// NOTE: only expose configuration that should be visible in the client side
const config = {
  analytics,
  api,
  environment,
  hubSpot,
  maps,
  sentry,
  site,
  validRoutes
};

export default config;
